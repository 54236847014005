<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-2xl font-bold mr-auto">聯絡我們</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome
              icon="tasks"
              type="fas"
              class="w-4 h-4 mr-1"
            />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <!-- <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button> -->
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
        >
          <template #modal="{ row, submit, reset }">
            <div class="flex items-center">
              <vxe-form
                ref="form"
                v-bind="formOptions"
                :data="row"
                @reset="reset"
                @submit="submit"
              >
                <template #column-member-id="{ data }">
                  <SelectBox
                    :transfer="true"
                    v-model="data.MemberId"
                    v-bind="memberSelectOptions"
                  />
                </template>
                <template #column-reply-content="{ data }">
                  <VxeSelect
                    class="mb-1"
                    v-model="data.ReplyType"
                    clearable
                    placeholder="選擇樣板"
                    @change="({ value }) => onReplyTypeChanged(value, data)"
                  >
                    <VxeOption
                      v-for="item in Object.values($enums.ContactUsType)"
                      :key="'reply-type-' + item.Value"
                      :label="item.Name"
                      :value="item.Value"
                    ></VxeOption>
                  </VxeSelect>
                  <VxeTextarea
                    v-model="data.ReplyContent"
                    :autosize="{ minRows: 8 }"
                    placeholder="請輸入回覆"
                  />
                </template>
              </vxe-form>
            </div>
          </template>
          <template #modal-footer>
            <vxe-button
              type="submit"
              status="primary"
              content="確認"
              @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"
            >
            </vxe-button>
            <vxe-button
              type="reset"
              content="重置"
              @click="$refs.form.dispatchEvent('reset')"
            ></vxe-button>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import CloudFun, { Condition, defineComponent, Operator, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import { helper as $h } from "@/utils/helper";
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});

    const gridOptions: GridOptions = {
      id: "user",
      title: "聯絡我們",
      canCreate: false,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      columns: [
        { field: "CreatedTime", title: "建立時間", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => $h.formatDate(cellValue, 'YYYY/MM/DD HH:mm:ss') },
        { field: "Name", title: "客戶姓名", showHeaderOverflow: true, showOverflow: true, sortable: true },
        {
          field: "Type",
          title: "問題類型",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => model ? Object.values(model.enums.ContactUsType).find(e => e.Value === cellValue)?.Name : undefined
        },
        { field: "ContactContent", title: "問題內容", formatter: ({ cellValue }) => cellValue?.substring(0, 20) },
      ],
      rowClassName(params) {
        return params.row.ReplyContent ? "" : "bg-indigo-100";
      },
      promises: {
        query: model ? (params) => {
          if (!params.sortings) params.sortings = [];
          params.sortings.push({ column: "CreatedTime", order: 1 });
          return model.dispatch("customerContact/query", params)
        } : undefined,
        queryAll: model ? () => model.dispatch("customerContact/query") : undefined,
        save: model ? (params) => model.dispatch("customerContact/save", params) : undefined,
      },
      modalConfig: { width: "80%", showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      items: [
        { field: "Name", title: "客戶姓名", span: 12, itemRender: { name: "$input", props: { disabled: true } } },
        { field: "MobilePhone", title: "行動電話", span: 12, itemRender: { name: "$input", props: { disabled: true } } },
        { field: "Email", title: "Email", span: 12, itemRender: { name: "$input", props: { disabled: true } } },
        // { field: "MemberId", title: "會員身分", span: 12, slots: { default: "column-member-id" } },
        { field: "Type", title: "問題類型", span: 12, itemRender: { name: "$select", props: { placeholder: "問題類型", disabled: true }, options: model ? Object.values(model.enums.ContactUsType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: "ContactContent", title: "問題內容", span: 24, itemRender: { name: "$textarea", props: { disabled: true, autosize: { minRows: 5 } } } },
        { field: "ReplyContent", title: "回覆內容", span: 24, slots: { default: "column-reply-content" } }
      ],
      rules: {
        Title: [{ required: true }],
        CategoryId: [{ required: true }],
      }
    };

    const memberSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'value',
      placeholder: '選擇會員',
      textField: 'label',
      valueField: 'value',
      columns: [
        { field: 'label', title: '姓名', showHeaderOverflow: true, showOverflow: true, sortable: true }
      ],
      promises: {
        find: (value) => new Promise(resolve => {
          model!.dispatch("member/find", value).then(res => resolve({ label: res.Person.Name, value: res.Id }));
        }),
        query: (params) => model!.dispatch("member/options", params) // eslint-disable-line
      },
    }

    return {
      grid,
      gridOptions,
      formOptions,
      memberSelectOptions
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async onReplyTypeChanged(value: number, payload: any) {
      const req = await this.$model.dispatch("replyTemplate/query", { condition: new Condition("Type", Operator.Equal, value), page: 1, pageSize: 1 })
      const template = req.data?.[0];
      payload.ReplyContent = template?.Content;
    }
  }
});
</script>
